.centerDescarga{
    display: flex;
    justify-content: center;
  }
  
  .cardDescargaTitulo {
    transition: transform 0.2s;
    border-radius: 10px;
    margin-bottom: 1.5%;
    padding: 0.75rem;
  
  
  }

  .botoncircDescarga {

    
    padding: 10px;
    font-size: 16px;
    background-color: #518ea6;
    color: #fff;
    border: 1px solid #ccc;
    border-radius: 50px;
    border-color: #ffffff;
    border-radius: 10px;
    border-style: double;
    cursor: pointer;
  
  }

  .centerDescarga2{
    display: flex;
    justify-content: center;
    flex-direction: row;

    @media (max-width: 1200px) {
      margin-top: 5%;
      flex-direction: column;
    }
  }
  
  .textoDescarga{
    display: flex;
    align-Items: center;
    justify-content: center;
    flex-direction: column;
    margin-left: 5%;
    @media (max-width: 1200px) {
      margin-left:0%;
    }

  }