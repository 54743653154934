.mis-avisos-container {
    width: 70%;
    margin: 0 auto;
    padding: 20px;
    @media (max-width: 800px) {
      width: 95%;
    }
  }
  
  .aviso-item {
    margin-bottom: 10px;
    list-style-type: none;
    border: 1px solid #ddd;
    padding: 10px;
    border-radius: 5px;
  }
  
  .align-top-centerMiPulsera  {
    display: flex; /* Opcional: usar Flexbox para el alineamiento */
    align-items: flex-start;
    justify-content: center; /* Esto alinea el contenido en el centro horizontal */

  }
  /* Puedes agregar más estilos según tus preferencias */
  