.imagenCartaGrandeHome {
  width: clamp(6rem, 4vh + 6rem, 10rem);
  transition: transform 0.3s;
}

.iconoCartaGrandeHome {
  width: 7rem;
  transition: transform 0.3s;
}

.iconoCartaGrandeHome:hover {
  transform: scale(1.05);
}

.imagenCartaGrandeHome:hover {
  transform: scale(1.05);
}
.botongraficoanimoCartaGrandeHome {
  position: relative;
  width: 3.5rem;
  height: 3.5rem;
  padding: 1px;
  /* margin: auto; */
  font-size: 100%;
  background-color: #FFFFFF;
  color: #fff;
  border: 3px solid #518ea6;
  border-radius: 50%; /* Hacerlo circular */
  cursor: pointer;
  transition: transform 0.3s;
}
.botongraficoanimoCartaGrandeHome > svg {
  width: 65%;
  height: 65%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.botongraficoanimoCartaGrandeHome:hover{
  transform: scale(1.05);
}
.botoncircCartaGrandeHome {
  width: 40%;

  padding: 3px;
  font-size: 20px;
  background-color: #518ea6;
  color: #fff;
  border: 1px solid #ccc;
  border-radius: 50px;
  border-color: #ffffff;
  border-radius: 10px;
  border-style: double;
  cursor: pointer;
  @media (max-width: 800px) {
  width: 80%;
  }
}

.CartaGrandeHome {
  /* font-size: 1.7rem; */
  display: flex;
  flex-flow: column nowrap;
  gap: 1.5rem;
}


.home-card-animo {
  margin-bottom: .5rem;
}
.home-card-animo .imagenCartaGrandeHome {
  width: 10rem;
}

.home-card-grande-datos {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}
  
.home-card-grande-datos > div {
  display: flex;
  flex-direction: column;
  align-items: center;
}
  
.home-card-grande-datos .home-progressBar-container {
  position: relative;
  padding: 0 0 1rem 0;
}
.home-card-grande-datos .home-progressBar-container > .home-progessBar-wrapper {
  margin: 0 auto;
  width: 95%;
}
.home-card-grande-datos .home-progressBar-icon {
  position: absolute;
  bottom: 0;
  right: 0;
  height: 20%;
}
.home-card-grande-datos .home-card-grande-datos-date {
  font-size: .8em;
}