.tabla-preguntas {
    border-collapse: collapse;
    width: 90%;
    margin: 0 auto; /* Esto centrará la tabla horizontalmente */

    @media (min-width: 1500px) {
      width: 60%;
    }
  }
  
  .tabla-preguntas th, .tabla-preguntas td {
    border: 1px solid #000;
    padding: 8px;
  }
  
  .tabla-preguntas th {
    background-color: lightgray;
  }
  
  @media (max-width: 768px) {
    .tabla-preguntas th, .tabla-preguntas td {
      border: 1px solid #000;
      padding: 4px;
      font-size: 11px;

      
    }

    .text-columna{
      font-size: "10px"
    }

      
  }

