.respuestaChecknoxes {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
  @media (max-width: 800px) {
    margin: 0;
    text-align: left;
  }
}
 

.checkbox{
  @media (max-width: 800px) {
    min-width: 30px;
  }
}