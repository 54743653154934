.InicioAdmin {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 5%;
  text-align: center;
}

.admin-text {
  margin-bottom: 10%;
  font-size: 24px;
  font-weight: bold;
}

.button-containerInicioAdmin {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  gap: 150px;
  margin: 0 20px;
}

.big-buttonInicioAdmin {
  padding: 50px 100px;
  font-size: 20px;
  border-radius: 50px; /* Hacer los botones redondos */
  border: none; /* Quitar el borde */
  cursor: pointer; /* Cambiar el cursor al pasar sobre el botón */
}

.big-buttonInicioAdmin:hover {
  background-color: #a7a7a7; /* Cambiar el color de fondo al pasar el cursor */
  color: white; /* Cambiar el color del texto al pasar el cursor */
  transform: scale(1.1); /* Aumentar ligeramente el tamaño del botón */
}
