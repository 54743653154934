.ConsultaAdmin {
  position: relative;
  flex: 1 1 0;
}

.ConsultaAdmin > .historic {
  position: absolute;
  top: 0;
  right: 0;
  width: 15%;
  height: 100%;
  text-align: left;
  background-color: rgba(0, 0, 0, 0.042);
}

.ConsultaAdmin-wrapper {
  width: 90%;
  max-width: 2000px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.2rem;
  font-size: 1.2rem;
}

.ConsultaAdmin-wrapper > .prompt-wrapper {
  width: 50%;
  display: flex;
  flex-direction: row;
  align-items: top;
  gap: .5rem;
  margin-top: 2rem;
}

.input-wrapper {
  flex: 1 1 0;
}
.input-wrapper > .input {
  width: 100%;
  border: 2px solid #518ea6;
  border-radius: .5rem;
  padding-left: .8rem;
  padding-right: .8rem;
}
.input-wrapper > .input:focus {
  outline: 2px solid #205165;
}
.input-wrapper > .input:read-only {
  background-color: #546b7424;
}


.prompt-wrapper > button {
  width: calc(2rem + .5rem * 2);
  height: calc(2rem + .5rem * 2);
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #518ea662;
  border-radius: .5rem;
  background-color: transparent;
}
.prompt-wrapper > button.active {
  border-color: #518ea6;
}
.prompt-wrapper > button.submit {
  background-color: #518ea6;
}
.prompt-wrapper > button:hover {
  background-color: #546b7424;
}
.prompt-wrapper > button.submit:hover {
  background-color: #518ea6b3;
}

.prompt-preview {
  width: 50%;
  min-height: 4lh;
  border: 2px solid #518ea6;
  border-radius: .5rem;
  text-align: left;
  padding: .5rem 1rem;
  background-color: #546b740d;
  font-size: .9em;
  font-family: "Roboto Mono", monospace;
}
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px); /* Optional: add slight movement */
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.fade-in {
  animation: fadeIn 0.5s ease-out forwards; /* Adjust duration and easing as needed */
}
.prompt-sql {
  white-space: pre-wrap;
}

.content-loader {
  font-size: .95em;
  line-height: 1.2;
}
.content-loader > .placeholder-text {
  display: inline-block;
  margin-right: 1rem;
  background-color: #518ea634;
  border-radius: .2rem;
  animation: loading 1.5s infinite;
}
.content-loader > .tab {
  display: inline-block;
  width: 3rem;
  height: 1rem;
}
.content-loader > .br {
  clear: both;
}
@keyframes loading {
  0% {
    background-color: #518ea634;
  }
  50% {
    background-color: #518ea619;
  }
  100% {
    background-color: #518ea634;
  }
}

.loader {
  width: 120px;
  height: 120px;
  border-width: 10px;
}

.prompt-result {
  min-width: 50%;
  max-width: 100%;
  overflow-x: scroll;
}
.prompt-result > .loader {
  transform: scale(.8);
  margin: 0 auto;
  margin-top: 3rem;
}
.prompt-result > table {
  margin-bottom: 3rem;
}

.ConsultaAdmin-wrapper > .error-message {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: .5rem 1rem;
  border-radius: .5rem;
  background-color: rgb(215, 78, 78);
  color: white;
  animation: opacity-fade 20s forwards;
}
.ConsultaAdmin-wrapper > .error-message > .close-icon {
  font-size: 2rem;
}
.ConsultaAdmin-wrapper > .error-message > .close-icon:hover {
  cursor: pointer;
}
@keyframes opacity-fade {
  0% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
