.cuestionario-container {
  margin-top: 5%;
  width: 60%;
  font-family: Arial, sans-serif;
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 50px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
  margin-bottom: 3%;

  @media (max-width: 800px) {
    width:  85%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 3%;
  }
}

h1 {
  color: #333;
}

.roott{

  display: flex;
  justify-content: center;

}

.mood-container {
  margin: 3%;
  display: flex;
  justify-content: center;
  gap: 2rem;

  @media (max-width: 800px) {
    flex-direction: column;
    align-items: center;
  }
}

.mood {
  width: 100px;
  height: 100px;
  margin: 0 10px;
  cursor: pointer;
  transition: transform 0.3s;
  @media (max-width: 800px) {
    margin-top:  5%;
  }
}

.mood:hover {
  transform: scale(1.05);
}
.moodchecked {
  width: 100px;
  height: 100px;
  margin: 0 10px;
  cursor: pointer;
  @media (max-width: 800px) {
    margin-top:  5%;
  }
}

.confirm-button {
  padding: 5px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.mastarde-button {

  padding: 10px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 5%;

}

.confirm-button:hover {
  background-color: #45a049;
}

.selected-mood-text {
  margin-top: 20px;
  color: #666;
}

.botoncirc-tarde {
  margin-top: 0px;
  width: 15%;
  padding: 10px;
  font-size: 16px;
  background-color: #518EA6;
  color: #fff;
  border: 1px solid #ccc;
  border-radius: 50px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  margin-bottom: 5%;

  @media (max-width: 800px) {
    width:  50%;;
  }
}


.botoncirc-conf {
  margin-top: 0px;
  width: 10%;
  padding: 10px;
  font-size: 16px;
  background-color: #518EA6;
  color: #fff;
  border: 1px solid #ccc;
  border-radius: 50px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  margin-bottom: 15px;

  @media (max-width: 800px) {
    width:  50%;;
  }
}

.imagenytexto{
  display: flex;
  flex-direction: column;
  gap: 1rem;
  font-size: 20px;
}