/* Publicidad.css */

.contenedor-pu {
  bottom: 0;
  left: 0;
  text-align: center;
  background-color:  #518ea6;
  width: 100%;
  overflow: hidden;
  z-index: 3; /* Ajusta el valor según sea necesario */
  margin-top: auto;
  transition: visibility 0s, opacity 0.5s linear;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}



.imagen-logospu {
  max-width: 25%; /* Establece el ancho deseado */
  height: auto; /* El valor "auto" mantiene la relación de aspecto original */
  /* Opcional: puedes ajustar la altura en lugar de o además del ancho */
  margin-top: 0.2%;
  margin-bottom: -0.5%;
  flex: 1;
  z-index: 3; /* Ajusta el valor según sea necesario */

  @media (max-width: 768px) {
    max-width: 90%;

  }
  
}
 