.centered-div {
  width: 25%;
  margin-left: 44%;

  display: flex;
  padding: 1%;
  margin: 1%
}

.tituloSuenoPageDuracion {
  display: flex;
  justify-content: center;
  /* Centra horizontalmente */
}

.containerGrafFechaSueno {

  width: 80%;

  @media (max-width: 800px) {
    width: 100%;
  }
}

.containerBodySueno {
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 50vh;
  /* Establece la altura al 90% de la altura de la ventana gráfica */
  margin-bottom: 7.5%;

  @media (max-width: 800px) {
    flex-direction: column;
    height: 10%;
    width: 100%;
  }
}

.cardSuenoPageDuracionTitulo {
  transition: transform 0.2s;
  border-radius: 5px;
  margin-bottom: 1.5%;
  padding: 0.75rem;

}



.graficoSuenoPage {
  display: flex;
  justify-content: center;
  /* Centra horizontalmente */
  width: 80%;
  height: 100%;
  margin-left: 8%;
  margin-right: 8%;
  margin-bottom: 8%;

  @media (max-width: 800px) {
    width: 100%;
    margin-left: 0%;
    margin-right: 0%;
  }
}

.cardInfoSuenoDuracion {
  background-color: #F0F8FF;
  border: 1px solid transparent;
  transition: transform 0.2s;
  border-radius: 10px;
  height: 20vh;
  width: 50vh;

  font-size: 2vh;
  box-shadow: 3px 2px 10px 2px rgba(0, 0, 0, 0.2);
  margin: 5%;

  @media (max-width: 15000px) {
    height: 22vh;
    width: 70vh;
    
  }

  @media (max-width: 800px) {
    font-size: 2.5vh;
    width: 100%;
    justify-content: flex-start;
    height: 100%;
    margin-top: 0%;
    height: 15rem;

  }

}


.centerSuenoPageDuracion1 {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(7rem, 7rem));
  /* Ajusta según sea necesario */
  justify-content: center;
  /* Centra horizontalmente */
}

.centerSuenoPage2 {
  display: flex;
  justify-content: center;
  /* Centra horizontalmente */




}

.centerSuenoPageDuracion3 {
  display: flex;
  justify-content: center;
  /* Centra horizontalmente */
  align-items: center;
  /* Centra verticalmente */
  flex-direction: column;
  margin-right: 15%;

  /* Agrega una media query para cambiar a columnas cuando el ancho es menor que cierto valor */
  @media (max-width: 800px) {
    display: flex;
    /* Centra horizontalmente */
    margin: 1%;
    margin-bottom: 10%;
    width: 90%;


  }

}

@media (max-width: 1000px) {
  .esconderEnPantallaPequena {
    display: none;
  }
}

.botongranuralidadSuenoPageDuracion {
  margin-top: 0px;
  width: 6.5%;
  padding: 1px;
  font-size: 16px;
  color: #fff;
  border-color: #ffffff;
  border-radius: 10px;
  border-style: double;
  cursor: pointer;
  margin-bottom: 15px;

  @media (max-width: 800px) {
    width: 25%;
  }
}

.botoncircSuenoPageDuracion {

  width: 100%;
  padding: 3px;
  font-size: 16px;
  background-color: #518ea6;
  color: #fff;
  border: 1px solid #ccc;
  border-radius: 50px;
  border-color: #ffffff;
  border-radius: 10px;
  border-style: double;
  cursor: pointer;

}