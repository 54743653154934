/* Estilos predeterminados para el navbar */
.custom-navbar {
  background-color:  #518ea6;
  width: 100%;
 
  display: flex;
  align-items: center; /* Centra verticalmente los elementos dentro del navbar */
  padding: 10px; /* Ajusta el relleno según tus preferencias */
  /* Otras propiedades de estilo que desees agregar */
}
.custom-navbar-right {
  background-color:  #518ea6; /* El mismo color de fondo que la primera navbar */
}
.nav-item {
  font-size: 18px; /* Tamaño de la fuente */
  height: 60px; /* Altura del elemento */
  display: flex;
  margin: 0.5%;
  min-height: 55px;
 
  max-height: 65px;
  margin-bottom: 5px;
  align-items: center; /* Centra verticalmente los elementos dentro de cada nav-item */
  /* Otras propiedades de estilo que desees agregar */
}
.nav-item-apartado {
  font-size: 18px; /* Tamaño de la fuente */
  height: 20px; /* Altura del elemento */
  margin: 0.5%;
  display:contents;
  min-height: 65px;
  max-height: 65px;
  margin-bottom: 5px;
  align-items: center; /* Centra verticalmente los elementos dentro de cada nav-item */
  /*
   Otras propiedades de estilo que desees agregar */
}
.nav-item-apartado > * {
  margin-right: auto; /* Alinea el texto a la izquierda del todo */
}
.anchonav{
width: 275px;
}
 
.navbar-nav {
  max-height: 75px ;
  background-color:  #518ea6;
 width: 100%;
}
 
/* Estilos para los enlaces del navbar */
.navbar-nav .nav-link {
  color: white !important;
  /* Otras propiedades de estilo que desees agregar */
}
 
@media (max-width: 1200px) {
  .anchonav{
    width: 175px;
    }
}
 
/* Estilos específicos para pantallas con un ancho menor o igual a 768px (tamaño de pantalla de un dispositivo típico) */
@media (max-width: 1000px) {
 
  .navbar-brand{
    padding: 10px;
  }
  .navbar-toggler{
    padding: 10px;
    margin-right: 5px;
  }
  .custom-navbar {
    width: auto; /* Eliminar la anchura fija en dispositivos móviles */
    padding: 0; /* Eliminar el relleno en dispositivos móviles */
    z-index: 100;
  }
 
 
  .navbar-nav {
    max-height: none;
    flex-direction: column; /* Cambia la dirección de la lista a columna */
    align-items: stretch; /* Estira los elementos para que ocupen todo el ancho */
  }
 
  .navbar-nav .nav-link {
    padding: 1%; /* Espaciado interno entre los elementos del menú */
    text-align: center; /* Centra el texto dentro de los elementos del menú */
   
  }
 
  .navbar-nav .nav-link:last-child {
    border-bottom: none; /* Elimina la línea divisoria del último elemento */
  }
}
 
 
.custom-navbar,
.custom-navbar-right {
  background-color:  #518ea6;
  display: flex;
  align-items: center;
  height: 75px; /* Altura deseada para ambas barras de navegación */
}
 
.custom-navbar .container-fluid,
.custom-navbar-right .container-fluid {
  padding: 0; /* Elimina el relleno predeterminado de los contenedores */
}
 
.custom-navbar ul.navbar-nav,
.custom-navbar-right ul.navbar-nav {
  margin: 0; /* Elimina el margen predeterminado de las listas */
  padding: 0; /* Elimina el relleno predeterminado de las listas */
}
 
.custom-navbar .navbar-brand,
.custom-navbar-right .navbar-brand {
  margin-right: auto; /* Mueve el logotipo a la izquierda */
}
 
.custom-navbar-right .navbar-nav {
  margin-left: auto; /* Mueve los elementos de la barra de navegación a la derecha */
}
 
.custom-navbar .nav-item,
.custom-navbar-right .nav-item {
  height: 100%; /* Asegura que todos los elementos tengan la misma altura */
  display: flex;
  align-items: center;
}
 
.custom-navbar .nav-link,
.custom-navbar-right .nav-link {
  align-items: right;
  color: white !important;
  /* Otras propiedades de estilo que desees agregar */
}
.active-link {
  background-color: #b9ddd7;
  border-radius: 15px;
  margin: 0.5%;
 
}
.navbar-toggler{

  position: relative;

}

.notification-indicator {

  position: absolute;

  top: 2.5px;

  left: 5px;

  transform: translate(-50%, -50%);

  width: 15px;   /* Tamaño del círculo */

  height: 15px;  /* Tamaño del círculo */

  background-color: red; /* Color del círculo */

  border-radius: 50%;    /* Hace el círculo perfecto */

  border: 0px solid white; /* Un borde blanco opcional */

}