.tooltip {
    position: absolute;
    padding: 5px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 3px;
    pointer-events: none;
    opacity: 0;
    transform: translateY(-10px);
    transition: opacity 0.3s ease, transform 0.3s ease;
  }
  
  .tooltip.visible {
    opacity: 1;
    transform: translateY(0);
  }
  
  .graph-line {
    stroke-dasharray: 0;
    stroke-dashoffset: 0;
    animation: draw 2s forwards;
  }
  
  .fill-area {
    opacity: 0;
    animation: fill 1s forwards;
  }
  
  .data-point {
    opacity: 0;
    animation: appear 1s forwards;
  }
  
  @keyframes draw {
    to {
      stroke-dashoffset: 0;
    }
  }
  
  @keyframes fill {
    to {
      opacity: 0.3;
    }
  }
  
  @keyframes appear {
    to {
      opacity: 1;
    }
  }
  