.imagenMisDatosSalud {
  margin-top: 4%;
  width: clamp(5rem, 3vh + 4rem, 6.5rem);
  transition: transform 0.3s;
}

.imagenMisDatosSalud:hover {
  transform: scale(1.05);
}

/* La última imagen no necesita margen derecho */