.body {
    margin: 0; /* Elimina el margen predeterminado del body */
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Establece la altura al 100% del viewport */
  }
  
  .titulo {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
    margin-top: 5%;
  }
  
  .subtitulo {
    font-size: 18px;
    margin-bottom: 5%;
    margin-top: 5%;
  }
  
  .botones {
    display: flex;
    justify-content: center;
    margin-top: 10%;
    
  }
  
  .botoncirc {
    margin-top: 0px;
    width: 30%;
    padding: 10px;
    font-size: 16px;
    background-color: #518EA6;
    color: #fff;
    border: 1px solid #ccc;
    border-radius: 50px;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
    cursor: pointer;
    margin-bottom: 15px;

    @media (max-width: 800px) {
      width:  50%;;
    }
  }
  
  .container{ 
    width: 100%;
    margin-top: 7.5%;
    border: 1px solid transparent;
    border-radius: 50px; 
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    padding: 15px;
    margin-bottom: 3%;

    @media (max-width: 800px) {
      width:  85%;;
    }
}