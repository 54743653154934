.containerLogin {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
}

.botoncircu {
  font-size: 16px;
  background-color: #518ea6;
  color: #fff;
  border: 1px solid #ccc;
  border-radius: 50px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
  top: 40px;
  /* Ajusta la distancia desde la parte superior según tus preferencias */
  right: 33%;
  /* Ajusta la distancia desde la derecha según tus preferencias */
  z-index: 3;
  /* Ajusta el valor según sea necesario */
  width: 15vh;
}

.button-style-android {
  transition: background-color .3s, box-shadow .3s;
  width: 220px;
  height: 50px;

  background-color: #e0ffe6;

  padding: 12px 16px 12px 42px;
  border: none;
  border-radius: 30px;
  box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 1px 1px rgba(0, 0, 0, .25);

  color: #5a5a5a;
  font-size: 14px;
  font-weight: 500;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;

  &:hover {
    box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 2px 4px rgba(0, 0, 0, .25);
  }

  &:active {
    background-color: #eeeeee;
  }

  &:focus {
    outline: none;
    box-shadow:
      0 -1px 0 rgba(0, 0, 0, .04),
      0 2px 4px rgba(0, 0, 0, .25),
      0 0 0 3px #c8dafc;
  }

  &:disabled {
    filter: grayscale(100%);
    background-color: #ebebeb;
    box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 1px 1px rgba(0, 0, 0, .25);
    cursor: not-allowed;
  }

  display: flex; 
  align-items: center;
  text-align: left;
}

.btnGoogle {
  transition: background-color .3s, box-shadow .3s;
  width: 220px;
  height: 50px;

  padding: 12px 16px 12px 42px;
  border: none;
  border-radius: 30px;
  box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 1px 1px rgba(0, 0, 0, .25);

  color: #5a5a5a;
  font-size: 14px;
  font-weight: 500;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;

  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTcuNiA5LjJsLS4xLTEuOEg5djMuNGg0LjhDMTMuNiAxMiAxMyAxMyAxMiAxMy42djIuMmgzYTguOCA4LjggMCAwIDAgMi42LTYuNnoiIGZpbGw9IiM0Mjg1RjQiIGZpbGwtcnVsZT0ibm9uemVybyIvPjxwYXRoIGQ9Ik05IDE4YzIuNCAwIDQuNS0uOCA2LTIuMmwtMy0yLjJhNS40IDUuNCAwIDAgMS04LTIuOUgxVjEzYTkgOSAwIDAgMCA4IDV6IiBmaWxsPSIjMzRBODUzIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNNCAxMC43YTUuNCA1LjQgMCAwIDEgMC0zLjRWNUgxYTkgOSAwIDAgMCAwIDhsMy0yLjN6IiBmaWxsPSIjRkJCQzA1IiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNOSAzLjZjMS4zIDAgMi41LjQgMy40IDEuM0wxNSAyLjNBOSA5IDAgMCAwIDEgNWwzIDIuNGE1LjQgNS40IDAgMCAxIDUtMy43eiIgZmlsbD0iI0VBNDMzNSIgZmlsbC1ydWxlPSJub256ZXJvIi8+PHBhdGggZD0iTTAgMGgxOHYxOEgweiIvPjwvZz48L3N2Zz4=);
  background-color: white;
  background-repeat: no-repeat;
  background-position: 12px 15px;


  &:hover {
    box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 2px 4px rgba(0, 0, 0, .25);
  }

  &:active {
    background-color: #eeeeee;
  }

  &:focus {
    outline: none;
    box-shadow:
      0 -1px 0 rgba(0, 0, 0, .04),
      0 2px 4px rgba(0, 0, 0, .25),
      0 0 0 3px #c8dafc;
  }

  &:disabled {
    filter: grayscale(100%);
    background-color: #ebebeb;
    box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 1px 1px rgba(0, 0, 0, .25);
    cursor: not-allowed;
  }

}

/* Agregar estilos específicos para tamaños de pantalla pequeños usando media queries */
@media (max-width: 768px) {
  .botoncircu {
    font-size: 16px;
    background-color: #518ea6;
    color: #fff;
    border: 1px solid #ccc;
    border-radius: 50px;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
    margin-top: 3%;
  }

}

.containerImagenIzq {
  background-image: url('/public/Images/imagen-login.jpg'); /* Reemplaza con el nombre de tu imagen */
  position: relative;
  background-size: cover; /* Asegura que la imagen cubra todo el contenedor */
  background-position: center 0px; /* Centra la imagen */
  background-repeat: no-repeat; /* Evita que la imagen se repita */
}

.containerImagenIzq::after {
  content: "Imagen de katemangostar en freepik.com";
  position: absolute;
  top: .5rem;
  left: 1rem;
  color: white;
  font-size: .8rem;
}

.containerBotonesLogin {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.cardBotonesLogin {
  border: 1px solid transparent;
  border-radius: 50px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  height: 80%; 
  width: 25%;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 800px) {

    width: 80%;
    height: 70%;
  }
}