/* usuariosAdmin.css */

.admin-page .modal-container.active {
  display: flex;
}
.admin-page .modal-container {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.5);
  display: none;
  align-items: center;
  justify-content: center;
}
.admin-page .modal-window {
  padding: 2rem;
  background-color: white;
  border: 3px solid #518ea6;
  border-radius: 10px;
}
.admin-page .modal-window input[type=text] {
  margin-top: 2rem;
  padding: .5rem;
  border: 3px solid #518ea6;
  border-radius: .75rem;
}
.admin-page .modal-window input[type=text]:focus {
  outline: none;
}
.admin-page .modal-window input[type=text].incorrect,
.admin-page .modal-window input[type=text].incorrect:focus {
  border-color: #c05e5e;
  outline: none;
}
.admin-page .modal-window .modal-buttons {
  margin-top: 2rem;
  display: flex;
  flex-direction: row wrap;
  gap: 1rem;
  justify-content: center;
}
.admin-page .modal-window .modal-buttons button {
  padding: .5rem 1rem;
  border: 2px solid #518ea6;
  border-radius: .75rem;
  background-color: white;
  cursor: pointer;
}
.admin-page .modal-window .modal-buttons button:hover {
  background-color: #518ea6;
  color: white;
}
.admin-page .modal-window .modal-buttons button.modal-delete {
  border-color: #c05e5e;
}
.admin-page .modal-window .modal-buttons button.modal-delete:hover {
  background-color: #c05e5e;
  color: white;
}
.admin-page .modal-window .modal-buttons button.modal-accept {
  border-color: #4ab153;
}
.admin-page .modal-window .modal-buttons button.modal-accept:hover {
  background-color: #4ab153;
  color: white;
}
.admin-page .modal-window .modal-buttons button:disabled {
  border-color: #94a5ac;
}

.admin-page .table-containerAdmin {
  margin: 0 auto;
  width: 80%;
}

.table-containerAdmin .table-settings {
  font-size: 1.1rem;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  justify-content: space-between;
  margin-bottom: 15px;
}
.table-settings > .table-settings-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.table-settings > .table-settings-row:first-child {
  display: flex;
  flex-direction: column;
  align-items: start;
}
.table-settings > .table-settings-row:last-child > div:last-child {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 3rem;
}
.table-settings .table-settings-item > *:first-child {
  margin-left: .7rem;
}
.table-settings select {
  padding: .3rem .5rem;
  border: 2px solid #518ea6;
  border-radius: .75rem;
}
.table-settings input[type=checkbox] {
  transform: translateY(30%) scale(1.70);
  -webkit-transform: translateY(30%) scale(1.70);
  -moz-transform: translateY(30%) scale(1.70);
}
.table-settings strong {
  display: inline-block;
  min-width: 5ch;
  padding: .1rem .3rem;
  border: 1px solid #518ea6;
  border-radius: .5rem;
  color: #366f85;
}
.table-settings svg {
  margin: 0 .1rem 0 1rem;
}

.tableAdmin-wrapper {
  width: 100%;
  overflow-x: scroll;
}
.tableAdmin {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  border-left: 1px solid #518ea6;
  border-right: 1px solid #518ea6;
  border-bottom: 1px solid #518ea6;
  border-radius: 15px 15px 0 0;
}

.tableAdmin thead > tr > th {
  border: 1px solid white;
  border-top-color: #518ea6;
  border-bottom-color: #518ea6;
  background-color: #518ea6;
  color: white;
  padding: 8px;
  text-align: center;
}
.tableAdmin thead > tr > th:first-child {
  border-left-color: #518ea6;
  border-top-left-radius: 15px;
}
.tableAdmin thead > tr > th:last-child {
  border-right-color: #518ea6;
  border-top-right-radius: 15px;
}
.tableAdmin thead {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  overflow: hidden;
}

.tableAdmin td {
  height: 100%;
  border: 1px solid #518ea6;
  padding: 8px 5px;
}
.tableAdmin tbody > tr {
  color: black;
}
.tableAdmin tbody > tr:nth-child(even) {
  background-color: #6fa3b81b;
}
.tableAdmin tbody > tr.done {
  color: rgba(0, 0, 0, 0.5);
}

.tableAdmin td.late-30 {
  background-color: #ec736335;
}
.table-settings svg.late-30 {
  color: #df4545bc;
}
.tableAdmin td.late-15 {
  background-color: #ecb56335;
}
.table-settings svg.late-15 {
  color: #ecb563bc;
}
.tableAdmin td.on-time {
  /* background-color: #fff; */
}
.table-settings svg.on-time {
  color: #fff;
}

.tableAdmin .row-downloads {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
  justify-content: center;
}
.tableAdmin tr .row-downloads button {
  color: black;
  padding: 5px 10px;
  background-color: white;
  border: 2px solid #518ea6;
  border-radius: 3px;
  cursor: pointer;
}
.tableAdmin tr.done .row-downloads button {
  color: rgba(0, 0, 0, 0.5);
  border-color: #518ea696;
}
.tableAdmin tr .row-downloads button:hover {
  background-color: #518ea6;
  color: white;
}

.tableAdmin .row-actions {
  display: flex;
  flex-flow: row wrap;
  min-height: 100%;
}
.tableAdmin .row-actions button {
  color: black;
  margin: 0 auto;
  height: 35px;
  width: 35px;
  background-color: white;
  border: 2px solid #518ea6;
  border-radius: 3px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tableAdmin tr.done .row-actions button {
  color: rgba(0, 0, 0, 0.5);
  border-color: #518ea696;
}
.tableAdmin tr .row-actions button:hover {
  border-color: #518EA6;
  background-color: #518EA6;
  color: white;
}
.tableAdmin tr .row-actions .action-delete:hover {
  border-color: #c05e5e;
  background-color: #c05e5e;
  color: white;
}

.spinner {
  border: 16px solid #f3f3f3;
  border-top: 16px solid #518EA6;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
  margin: 0 auto;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.loader {
  margin: 0 auto;
  margin-top: 3rem;
}


/* Media query para ocultar el correo electrónico en pantallas móviles */
@media (max-width: 600px) {
  .admin-page .table-container table td:nth-child(3),
  .admin-page .table-container table th:nth-child(3) {
    display: none;
  }
}


.loader {
  margin: 0 autoS;
}
