.mi-pulsera-wrapper {
  width: 100%;
  overflow: hidden;
}

.card-container {
  place-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 25px;
  height: 60vh;

  @media (max-width: 1200px) {
    height: 100%;
    width: 95%;
    margin: 0 auto;
    flex-direction: column;
    align-items: stretch;
  }
}

.linkayudasinc{
  margin: 0 auto; 
  margin-bottom: 10px; 
  color: #518ea6; 
  text-decoration: underline !important;
}

.cardMiPulsera {
  background-color: #F0F8FF;
  border: 1px solid transparent;
  transition: transform 0.2s;
  border-radius: 10px;
  box-shadow: 3px 2px 10px 2px rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 35rem;
}

.cardMiPulsera:hover {
  transform: scale(1.05);
}

.cardMiPulseraTitulo {
  transition: transform 0.2s;

  padding: 0.75rem;
}





@media (max-width: 1200px) {
  .cardMiPulsera:hover {
    transform: scale(1);
  }

  .cardMiPulsera {
    width: 98%;
  }


}