.custom-table {
  border-collapse: separate;
  border-spacing: 15px; /* Aumenta la separación entre las filas y las columnas de la tabla */
  margin: 0 auto; /* Centra la tabla horizontalmente en el contenedor */
  width: auto; /* Ajusta el ancho de la tabla según tus necesidades */
  text-align: left;
  @media (max-width: 800px) {
    border-spacing: 0px;
  }
  
}

.first-column {
  width: 75px; /* Aumenta el ancho de la primera columna según tus necesidades */
  
}

.answer-container {
  display: flex;
  align-items: center;
}

.answer-box {
  border: 1px solid #000;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s;
  min-width: 70px; /* Aumenta el ancho mínimo para permitir más espacio para el texto */
  min-height: 60px;
  padding: 5px;
  margin-right: 10px;
  width: 75px; /* Alinea el ancho con el ancho mínimo */

  @media (max-width: 800px) {
    min-width: 50px;
    width: 50px;
    margin-right: 0px;
  }
}

.answer-box2 {
  border: 1px solid #000;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s;
  min-width: 70px; /* Aumenta el ancho mínimo para permitir más espacio para el texto */
  min-height: 60px;
  padding: 5px;
  margin-right: 10px;
  width: 75px; /* Alinea el ancho con el ancho mínimo */

  @media (max-width: 800px) {
    min-width: 50px;
    width: 50px;
    margin-right: 0px;
  }
}

.answer-box.selected {
  border-color: lightblue;
    background-color: lightblue;
    color: white;
}

.answer-text {
  white-space: nowrap; /* Permite que el texto se desplace a varias líneas para mostrarlo completo */
  overflow: visible; /* Evita que el texto se corte o se oculte */
  text-overflow: clip; /* Muestra todo el texto sin recortarlo */
  text-align: center;
}

/* Establecer la posición horizontal para los cuadros de respuesta */
/* Establecer la posición horizontal para los cuadros de respuesta */
.answer-box:nth-child(1) {
  left: 0; /* El primer cuadro */
}

.answer-box:nth-child(2) {
  left: 80px; /* El segundo cuadro (ajusta el valor según tu preferencia) */


}

.answer-box:nth-child(3) {
  left: 160px; /* El tercer cuadro (ajusta el valor según tu preferencia) */
}
