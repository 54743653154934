/* EscalavisualanalogicaHorizontal.css */

/* Línea principal */
.lineaH {
  width: 50%; /* Ancho relativo para adaptabilidad */
  height: 5px; /* Altura de la línea horizontal */
  background-color: #000000;
  position: relative;
  margin: 0 auto;
  cursor: pointer;
  z-index: 1;
}

.escalavisualanalogica-containerH{
  margin-top: 10%;
  padding: 0 30px;
}
/* Área adicional para clics */
.lineaH::before {
  content: "";
  position: absolute;
  top: -10px;
  bottom: -30px; /* Ajusta el margen inferior para el área de clic */
  left: -10px; /* Ajusta el margen izquierdo para el área de clic */
  right: -10px;
  z-index: 0;
}
/* Estilo para el contenedor de la línea */
.linea-containerH {
  margin-top: 50px; /* Ajusta el margen superior según tu preferencia */
}
/* Estilo para el contenedor del texto del resultado */
.textoResultadoH {
  margin-top: 35px; /* Ajusta el margen superior según tu preferencia */
}

/* Marcas */
.marcasH {
  position: relative;
  top: 0;
  left: 0%; /* Deja el margen izquierdo en 0 para adaptabilidad */
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

/* Marca individual */
.marcaH {
  position: relative;
  width: 2px;
  height: 20px; /* Altura de las marcas verticales */
  background-color: #000;
  top: 0;
  transform: translateY(-50%); /* Centra verticalmente la marca */
}

/* Número debajo de la marca */
.numeroH {
  position: absolute;
  top: 100%;
  left: 50%;
  font-size: 12px;
  transform: translateX(-50%);
}

/* Estilo para el texto izquierdo */
.textoIzquierdaH {
  text-align: left;
  word-wrap: pre-line;
}

/* Estilo para el texto derecho */
.textoDerechaH {
  text-align: right;
  word-wrap: pre-line;
}

/* Estilo para el contenedor de texto */
.texto-containerH {
  width: 57%; /* Ajusta según el ancho deseado, teniendo en cuenta el espacio de los textos */
  margin: 0 auto; /* Centra horizontalmente el contenedor */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
  @media (max-width: 800px) {
    width: 50%;
  }
}

.lineaRoja {
  position: absolute;
  height: 15px;
  width: 15px;
  background-color: red;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  margin-left: -2px;
  bottom: 0;
  top: 50%;
  pointer-events: none;
}


/* Media Query para pantallas pequeñas */
@media screen and (max-width: 768px) {
  .lineaH {
      width: 80%; /* Ancho relativo para pantallas pequeñas */
  }
  .texto-containerH {
    width: 82%; /* Ancho relativo para pantallas pequeñas */
}
}
