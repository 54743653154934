.single-line-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 2.5rem; /* Ajusta este valor según sea necesario */
    margin-left:10px ;
  }
  
  @media (max-width: 768px) {
    .single-line-title {
      font-size: 4vw; /* Ajusta este valor para pantallas más pequeñas */
      font-weight: bold;
    }
  }
  