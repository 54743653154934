.botonTemaSeleccionadoTecuido,
.botonTemaDeseleccionadoTecuido {
  margin-top: 0px;
  width: 100%;
  padding: 1px;
  font-size: 16px;
  background-color: #518EA6;
  color: white;
  cursor: pointer;
  margin-bottom: 15px;
  border-color: #ffffff;
  border-radius: 10px;
  border-style: double;
  height: 55px;
}

.botonTemaDeseleccionadoTecuido {
  background-color: #b9ddd7;
  color: black;
}

@media (max-width: 800px) {
  .botonTemaSeleccionadoTecuido,
  .botonTemaDeseleccionadoTecuido {
    width: 100%;
    font-size: 1.5vh;
  }
}

.imagenTecuido {
  width: 50%;
  height: 50%;
}

.tituloTecuido {
  display: flex;
  justify-content: center;
}

.agrupacionTecuido {
  display: flex;
  justify-content: center;
}

.textoTecuido {
  width: 70%;
  margin: 0 auto;
  @media (max-width: 800px) {
    width: 95%;
    font-size: 2vh;
    margin: 0 auto;
  }
}

.botonesalienadosTecuido {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 60%;
  margin: 0 auto;
  margin-top: 0.5%;
  margin-bottom: 1%;
  @media (max-width: 800px) {
    width: 95%;

  }
}