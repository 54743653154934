.cardExpliacion {
    background-color: #f6fdf8 ;
    border: 1px solid #000;
    transition: transform 0.2s;
    width: 50%;
    border-radius: 10px;
    box-shadow: 3px 2px 10px 2px rgba(0, 0, 0, 0.1);
    padding: 0.5%;
    margin-left: 25%;
    @media (max-width: 800px) {
        width: 85%;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 5%;
      }
   
  }

  .buttonCuestionario9semanal {
    width: 20%;
    padding: 10px;
    text-align: center;
    background-color: #518EA6;
    color: #fff;
    border: none;
    border-radius: 50px;
    cursor: pointer;
    margin-top: -0.5%;
    margin-bottom: 1%;
    transition: transform 0.3s;
    @media (max-width: 800px) {
        width: 90%;
        margin-left: 0%;
        margin-right: 0%;
        margin-top: -5%;
        margin-bottom: 5%;
      }
  }