/* Estilos para Ayuda.js */

.containerAyuda {
    justify-content: center;
    margin-top: 5%;
    width: 80%;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 50px;
    margin-bottom: 10%;
    @media (max-width: 800px) {
        width: 85%;
      }
}

.tituloAyuda {
    color: #333;
    text-align: center;
    margin-top: 5%; /* Puedes ajustar este valor según sea necesario */
}

.contenidoAyuda {
    text-align: justify;
    margin-top: 4%;
    margin-right: 7%;
    margin-left: 7%;
}

.subtituloAyuda {
    font-size: 150%;
    color: #518EA6;
    margin-top: 20px;
    font-weight: 700;
}

