.loading-screen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .loader {
    border: 32px solid #e2dff2; /* Light grey */
    border-top: 32px solid #518ea6; /* Blue */
    border-radius: 50%;
    width: 240px;
    height: 240px;
    animation: spin 2s linear infinite;
    @media (max-width: 800px) {
      border: 16px solid #e2dff2; /* Light grey */
      border-top: 16px solid #518ea6; /* Blue */
      border-radius: 50%;
      width: 120px;
      height: 120px;
    }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  