/* Home.css */

/* Default styles for larger screens */


.mainContainerHome {
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;

  font-size: clamp(0.8rem, 1vh + 0.8rem, 1.7rem);
}

.margentitulohome {
  margin-top: 1rem;
  margin-bottom: 6vh;

  font-size: 2rem;
}

.center-tableHome {
  width: 80%;
  max-width: 2000px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  gap: 1.5vw;
  margin-bottom: 1rem;

  /*   flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  max-width: 100%;
  width: 100%; */
}

.cardhomegrande {
  flex: 0 0 auto;
  width: 33%;
  min-height: 55vh;

  background-color: #F0F8FF;
  border: 1px solid transparent;
  transition: transform 0.2s;
  border-radius: 10px;
  box-shadow: 3px 2px 10px 2px #e2dff2;
  transition: transform 0.3s;
  padding: 0 1rem 1rem 1rem;
  /* margin-top: 1.4rem;
  margin-right: 2vh;
  margin-bottom: 1.5rem; */
}

.home-right-container {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 5vh 1.5vw;  
}

.cardhome {
  flex: 1 1 calc(50% - 6vw);
  max-width: 49%;

  background-color: #F0F8FF;
  transition: transform 0.2s;
  border-radius: 10px;
  box-shadow: 3px 2px 10px 2px #e2dff2;
  transition: transform 0.3s;
  padding: 0 1rem;
  /* margin-top: 2.5vh;
  margin-right: 2vh; */
}

.cardhome:hover {
  transform: scale(1.05);
}

a {
  text-decoration: none !important;
  color: #000;
  z-index: 2;
}

.contenedor-imagen2 {
  position: fixed;
  bottom: 0;
  left: 50%;
  text-align: center;
  z-index: 0.5;
}

.home-card-header {
  z-index: 2;
  margin-top: 0.6rem;
  font-size: 1.25em;
  background-color: #518EA6;
  border: 1px solid transparent;
  border-radius: 10px;
  box-shadow: 3px 2px 10px 2px rgba(0, 0, 0, 0.1);
  color: white;
}









@media (max-width: 1280px) {
  .margentitulohome {
    margin-top: 5rem;
    margin-bottom: 4rem;
  }
  .center-tableHome {
    width: 100%;
    padding: 0 1.5rem 2rem 1.5rem;
  }
  .cardhomegrande {
    width: 50%;
  }
  .cardhome {
    flex: 1 1 100%;
    max-width: 100%;
  }
}
@media (max-width: 1024px) {
  .center-tableHome {
    flex-direction: column;
    gap: 6vh;
    margin-bottom: 0;
  }
  .home-right-container {
    gap: 6vh;
  }
  .cardhomegrande {
    width: 100%;
    min-height: auto;
  }
  .cardhome {
    flex: 1 1 100%;
    max-width: 100%;
  }
}

@media (max-width: 768px) {

}

@media (max-width: 640px) {

}

