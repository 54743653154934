.select-tag-custom-tag-select-container {
  display: inline-block;
  min-width: 325px;
  position: relative;
}

.select-tag-custom-select-input {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  border: 2px solid #518ea6;
  border-radius: 5px;
  padding: 5px;
  cursor: pointer;
  position: relative;
}
.select-tag-custom-select-input input {
  cursor: pointer;
}

.select-tag-select-input {
  border: none;
  outline: none;
  width: 100%;
  background: transparent;
  padding: 5px;
}

.select-tag-user-selected-tag {
  display: flex;
  align-items: center;
  background-color: #e2e2e2;
  border-radius: 10px;
  padding: 5px 10px;
  margin-right: 5px;
  margin-bottom: 5px;
}

.select-tag-user-selected-tag-button {
  margin-left: 5px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  font-weight: bold;
}

.select-tag-dropdown {
  position: absolute;
  width: 100%;
  border: 1px solid #518ea6;
  background-color: #fff;
  max-height: 220px;
  overflow-y: auto;
  z-index: 1000;
  border-radius: 0 0 5px 5px;
  padding: 2px;
  box-shadow: 0px 2px 21px -8px rgba(0,0,0,0.select-tag-65);
}
.select-tag-dropdown-item {
  padding: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.select-tag-dropdown-item-label {
  flex: 1 1 auto;
  text-align: center;
}
.select-tag-dropdown-item:hover {
  background-color: #f0f0f0;
}