.intentosContainer{ 
    margin: 0 auto;
    margin-top: 5%;
    border: 1px solid transparent;
    border-radius: 50px; 
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    padding: 15px;
    margin-bottom: 5%;
    min-height: 50%;
    height: 100%;
    width: 50%;

    @media (max-width: 800px) {
      width:  85%;
      margin-left: auto;
      margin-right: auto;
    }
}

.tableIntento {
  min-width: 50%;
  margin: 0 auto; /* Centrará la tabla horizontalmente */
  margin-bottom: 10px; /* Ajusta el margen inferior según sea necesario */
  border-collapse: separate;
}


.intentoContainer{
  flex-direction: column;
  display: flex;
  align-items: center;
  height: 75vh; /* Ajusta la altura según sea necesario */
}

.intentosTableHeader {
  text-align: left;
  padding: 10px 0 5px 10px;
  margin-bottom: 50px;
}

.tituloTabla{
  margin: 0 auto;
  margin-bottom: 3%;
  margin-top: 1%;
  font-weight: bold;
} 

.intento-button {
  padding: 1rem 2rem;
  margin: 2rem auto 1rem auto;
  text-align: center;
  background-color: #518EA6;
  color: #fff;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  transition: transform 0.3s;
}

@media (max-width: 768px) {
  /* Estilos específicos para dispositivos móviles */
  .tableIntento {
    border-spacing: 20px;

    min-width: 100%;
  }

  .tituloTabla{
    margin-left: 2%;
    margin-right: 2%;
    margin-top: 15%;
    margin-bottom: 10%;
  }

  .intento-button {
    width: 70%;
    margin: 7% 0;
  }
}