/* Estilos para AyudaSincronizacion.js */

.containerAyudaSincronizacion {
    justify-content: center;
    margin-top: 5%;
    width: 80%;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 50px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 10%;
    @media (max-width: 800px) {
        width: 85%;
      }
}

.tituloAyudaSincronizacion {
    color: #333;
    text-align: center;
    margin-top: 5%; /* Puedes ajustar este valor según sea necesario */
}

.contenidoAyudaSincronizacion {
    text-align: justify;
    margin-top: 4%;
    margin-right: 7%;
    margin-left: 7%;
}

.subtituloAyudaSincronizacion {
    font-size: 150%;
    color: #518EA6;
    margin-top: 20px;
    font-weight: 700;
}


.imagenAyudaSincronizacion  {
    margin-top: 10px;
    width: 25%;
   
    text-align: center;
  }