.custom-tag-select-container {
  max-width: 325px;
  position: relative;
}

.custom-select-input {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 5px;
  cursor: pointer;
  position: relative;
}
.custom-select-input input {
  cursor: pointer;
}

.select-input {
  border: none;
  outline: none;
  width: 100%;
  background: transparent;
  padding: 5px;
}

.user-selected-tag {
  display: flex;
  align-items: center;
  background-color: #e2e2e2;
  border-radius: 10px;
  padding: 5px 10px;
  margin-right: 5px;
  margin-bottom: 5px;
}

.user-selected-tag-button {
  margin-left: 5px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  font-weight: bold;
}

.dropdown {
  position: absolute;
  width: 100%;
  border: 1px solid #518ea6;
  background-color: #fff;
  max-height: 220px;
  overflow-y: auto;
  z-index: 1000;
  border-radius: 0 0 5px 5px;
  padding: 2px;
  box-shadow: 0px 2px 21px -8px rgba(0,0,0,0.65);
}
.dropdown-item {
  padding: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.dropdown-item-label {
  flex: 1 1 auto;
  text-align: center;
}
.dropdown-item:hover {
  background-color: #f0f0f0;
}
.dropdown-item-delete:hover {
  color: #c05e5e;
}

.dropdown .new-tag-input {
  width: 100%;
  border: none;
  outline: none;
  border-top: 1px solid #ccc;
  padding: 5px;
}


.dropdown .delete-tag-modal-container {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}
.dropdown .delete-tag-modal-window {
  padding: 2rem;
  background-color: white;
  border: 3px solid #518ea6;
  border-radius: 10px;
}
.dropdown .delete-tag-modal-window .modal-buttons {
  margin-top: 2rem;
  display: flex;
  flex-direction: row wrap;
  gap: 1rem;
  justify-content: center;
}
.dropdown .delete-tag-modal-window .modal-buttons button {
  padding: .5rem 1rem;
  border: 2px solid #518ea6;
  border-radius: .75rem;
  background-color: white;
  cursor: pointer;
}
.dropdown .delete-tag-modal-window .modal-buttons button:hover {
  background-color: #518ea6;
  color: white;
}
.dropdown .delete-tag-modal-window .modal-buttons button.modal-delete {
  border-color: #c05e5e;
}
.dropdown .delete-tag-modal-window .modal-buttons button.modal-delete:hover {
  background-color: #c05e5e;
  color: white;
}