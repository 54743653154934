.imagenSuenoCarta {
    width: 4rem;
    height: 4rem;
  }

  .chart-suenoCarta > div:first-child {
    border-top-left-radius: .6rem;
    border-bottom-left-radius: .6rem;
  }
  .chart-suenoCarta > div:last-child {
    border-top-right-radius: .6rem;
    border-bottom-right-radius: .6rem;
  }
    