/* Escalavisualanalogica.css */

.lineaV {
  width: 5px;
  height: 500px;
  background-color: #000000;
  position: relative;
  margin: 0 auto;
  cursor: pointer;
  z-index: 1; /* Asegura que la línea principal esté en la capa superior */
}

/* Crear un área adicional para clics */
.lineaV::before {
  content: "";
  position: absolute;
  top: -10px;
  bottom: -10px;
  left: -30px; /* Ajusta el margen izquierdo para el área de clic */
  right: -60px; /* Ajusta el margen derecho para el área de clic */
  z-index: 0; /* Coloca la pseudo-clase en una capa inferior */
}

.marcasV {
  position: relative;
  top: 0px; /* Ajusta la posición vertical inicial para bajar la línea */
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.marcaV {
  position: relative;
  height: 2px; /* Ajusta la altura de las marcas según sea necesario */
  background-color: #000;
  left: 50%; /* Centra la marca horizontalmente en la línea */
  transform: translate(-50%, -50%); /* Centra verticalmente y horizontalmente la marca */
}
.numeroV {
  position: absolute;
  top: 0px; /* Ajusta la posición vertical de los números al centro de la línea */
  left: 125%; /* Ajusta el valor de left según sea necesario */
  font-size: 12px;
  transform: translate(-50%, -50%); /* Centra verticalmente y horizontalmente los números */
}

.puntoRojo {
  width: 15px; /* Ancho del punto rojo */
  height: 15px; /* Altura del punto rojo */
  background-color: red; /* Color del punto rojo */
  border-radius: 50%; /* Para que sea un círculo */
  position: absolute;
}
