.botoncircPaginacionTemas {

    width: 10vh;
    padding: 3px;
    font-size: 16px;
    background-color: #518ea6;
    color: #fff;
    border: 1px solid #ccc;
    border-color: #ffffff;
    border-radius: 10px;
    border-style: double;
    cursor: pointer;
    margin-top: 15px;
    @media (max-width: 800px) {
        width: 17.5vh;
        font-size: 1.5vh;
        margin-bottom: 10%;
      }
  }

  
.video-containerPaginacionTemas {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .videointroductorioPaginacionTemas {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); /* Ajusta los valores según tu preferencia */
    
  }
  
.tituloPaginacionTemas{
  margin: auto;
  font-size: 20px;
  width: 70%;
  @media (max-width: 800px) {
    width: 90%;
  }
}
  /* .play-button {
    position: absolute;
    top: 47.5%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 10px 15px;
    font-size: 75px;
    color: white;
    background-color: transparent;
    border-radius: 100%;
    border-width: 0;
    cursor: pointer;
  
    
  } */