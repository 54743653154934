.botoncirc {
    margin-top: 0px;
    width: 30%;
    padding: 10px;
    font-size: 16px;
    background-color: #518EA6;
    color: #fff;
    border: 1px solid #ccc;
    border-radius: 10px;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
    cursor: pointer;
    margin-bottom: 15px;

    @media (max-width: 800px) {
      width:  100%;
    }
  }

  .cuestionarioContainer{ 
    margin-left: auto;
    margin-right: auto;
    margin-top: 1%;
    border: 1px solid transparent;
    border-radius: 20px; 
    padding: 15px;
    margin-bottom: 5%;
    width:  80%;
    @media (max-width: 800px) {
      width:  85%;
      margin-left: auto;
      margin-right: auto;
    }
}