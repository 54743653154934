/* FormularioRegistro.css */
.formulario-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    width: 25%;
    min-width: 350px;
    margin: 0 auto;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 1%;
    padding: 1%;
    border-radius: 5%;
    height: 80%;
    margin-bottom: 5%;
  }
  
  .mensaje-bienvenida {
    margin-top: 2%;
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 3%;
    margin-right: 10px;
    margin-left: 10px;
  }
  
  .formulario {
    width: 100%;
  }
  
  /* Estilos para los elementos internos del formulario */
  .formulario label {
    font-size: 16px;
    margin-top: 20px;
    color: #333;
    width: 100%;
  }
  
  .formulario input,
    .formulario select {
    width: 60%;
    padding: 10px;
    margin-bottom: 15px;
    font-size: 16px;
    border: none;
    border-bottom: 1px solid #ccc;
    outline: none;
    color: #333;
    }
    .botoncircul{
      font-size: 16px;
      background-color: #518EA6;
      color: #fff;
      border: 1px solid #ccc;
      border-radius: 50px;
      box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
      
      width: 5%;
      margin-left: 80%;
      margin-top: 1%;
    }

    .botoncirc {
        margin-top: 10%;
        width: 30%;
        padding: 10px;
        font-size: 16px;
        background-color: #518EA6;
        color: #fff;
        border: 1px solid #ccc;
        border-radius: 50px;
        box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
        cursor: pointer;
        margin-bottom: 15px;
      }
      
      .error-message {
        color: red;
        margin-top: 5px; /* Agrega un pequeño espacio arriba del mensaje de error */
      }