.tooltip {
    font-family: 'Arial', sans-serif;
    font-size: 12px;
    fill: white;
  }
  
.anchoventana{
  width: 100%;
  margin: 0 auto;
  @media (max-width: 800px) {
    width: 100%;
  }
}