.centered-div {
    width: 25%;
    margin-left: 44%;
    
    display: flex;
    padding: 1%;
   
  }
  .tituloSuenoPage {
    display: flex;
    justify-content: center; /* Centra horizontalmente */
     
    
  }
  .cardSuenoPageTitulo{
  transition: transform 0.2s;
  border-radius: 5px;
  margin-bottom: 1.5%;
  padding: 0.75rem;}

  .cardFasesSuenoSuenoPage {
    background-color: #F0F8FF ;
    border: 1px solid transparent;
    transition: transform 0.2s;
    border-radius: 10px;
    width: 46.5%;
    height: 10rem;
    box-shadow: 3px 2px 10px 2px rgba(0, 0, 0, 0.1);
    margin-bottom: 10%;
    
  
    @media (max-width: 800px) {
      width: 95%;
      height: 8rem;
  
    }
  }
.chart-fasesSueno > div:first-child {
  border-top-left-radius: .6rem;
  border-bottom-left-radius: .6rem;
}
.chart-fasesSueno > div:last-child {
  border-top-right-radius: .6rem;
  border-bottom-right-radius: .6rem;
}

  .cardInfoSueno {
    background-color: #F0F8FF ;
    border: 1px solid transparent;
    transition: transform 0.2s;
    border-radius: 10px;
    height: 20vh;
    width:50vh ;
    font-size: 2vh;
    box-shadow: 3px 2px 10px 2px rgba(0, 0, 0, 0.2);
    @media (max-width: 800px) {
      width: 100%;
     
  
    }
  
  }
  .centerSuenoPage1 {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(7rem, 7rem)); /* Ajusta según sea necesario */
    justify-content: center; /* Centra horizontalmente */
  }

  .centerSuenoPage2 {
    display: flex;
    justify-content: center; /* Centra horizontalmente */
   
     
     
    
  }
  .centerSuenoPage3 {
    display: flex;
    justify-content: center; /* Centra horizontalmente */
    align-items: center; /* Centra verticalmente */
    /* Agrega una media query para cambiar a columnas cuando el ancho es menor que cierto valor */
    @media (max-width: 800px) {
      display:contents;
      
  
    }

  }

    @media (max-width: 1000px) {
      .esconderEnPantallaPequena {
        display: none;
      }
    }

    .botongranuralidadSuenoPage {
      margin-top: 0px;
      width: 6.5%;
      padding: 1px;
      font-size: 16px;
      color: black;
      background-color: #b9ddd7;
      border-color: #ffffff;
      border-radius: 10px;
      border-style: double;
      cursor: pointer;
      margin-bottom: 15px;
      @media (max-width: 800px) {
        width: 25%;
      }
    }

    .botoncircSuenoPage {

      width: 100%;
      padding: 3px;
      font-size: 16px;
      background-color: #518ea6;
      color: #fff;
      border: 1px solid #ccc;
      border-radius: 50px;
      border-color: #ffffff;
      border-radius: 10px;
      border-style: double;
      cursor: pointer;

    }
    
  
  
  