.mis-avisos-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.section {
  border: 1px solid transparent;
  border-radius: 30px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  align-items: center;
  width: 100%;
  min-height: 13vh;
  display: flex;
  justify-content: center;
}

.section2 {
  border: 1px solid transparent;
  border-radius: 30px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  align-items: center;
  width: 30%;
  min-height: 13vh;
  display: flex;
  justify-content: center;
  margin: auto;
}

.contenedorLeyenda{
  display: flex;
  flex-direction: row;
  margin: auto;
  margin-bottom: 1%;
  justify-content: left;
  margin-top: 5%;
}

.section-title {
  font-size: 18px;
  font-weight: bold;

  margin-top: 5%;
}

.link-container {
  flex: 1;
  flex-direction: column;
  align-items: center;
}

.link-button {
  width: 25vh;
  padding: 10px;
  text-align: center;
  background-color: #518EA6;
  color: #fff;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  transition: transform 0.3s;
  margin: 7% auto; /* Ajusta el margen superior e inferior según tus necesidades */
}

.link-button:hover {
  background-color: #518EA6;
  transform: scale(1.05);
}

/* Media query para pantallas pequeñas (dispositivos móviles) */
@media (max-width: 768px) {
  .row {
    flex-direction: column;
  }

  .link-container {
    margin-bottom: 10px;
    
  }
  .section {
    width: 90%;
    margin: 0 auto;
  }

  .link-button {
    width: 80%;
    margin: 7% 0;
  }
}
