.align-top-centerVideo {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.react-player-container {
  width: 100%;
  height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Media query para dispositivos móviles */
@media (max-width: 768px) {
  .align-top-centerVideo {
    display: none; /* Ocultar en dispositivos móviles */
  }

  .react-player-container {
    height: 60vh; 
    display: flex;
    justify-content: center;
    align-items: center;
    
  }
}

/* .play-buttonVideoViewer {
  position: absolute;
  top: 48%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 10px 15px;
  font-size: 200px;
  color: white;
  background-color: transparent;
  border-radius: 100%;
  border-width: 0;
  cursor: pointer;
} */
