.cardRellenoRecomendados {
    background-color: #ffffff;
    margin: 15px;
    border: 2px solid transparent;
    /* border-color: #9A91D2; */
    transition: transform 0.2s;
    border-radius: 10px;
    box-shadow: 3px 2px 10px 2px rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 35rem;
  
  }
  .cardRellenoRecomendados.presentacionBorder {
    border-color: #518ea6;
  }
  
  .cardRellenoRecomendados.infografiaBorder {
    border-color: #7fad96;
  }
  
  .cardRellenoRecomendados.tareaBorder {
    border-color: #7063bf;
  }
  .cardRellenoRecomendados.material_complementarioBorder {
    border-color: #d19451;
  }
  
  .cardRellenoRecomendados.videoBorder {
    border-color: #ef9a9a;
  }
/*   
  .cardRellenoRecomendados.presentacionBorder {
    border-color: #cadde4;
  }
  
  .cardRellenoRecomendados.infografiaBorder {
    border-color: #d0e4ca;
  }
  
  .cardRellenoRecomendados.tareaBorder {
    border-color: #d9d7ed;
  }
  .cardRellenoRecomendados.material_complementarioBorder {
    border-color: #f8e0cc;
  }
  
  .cardRellenoRecomendados.videoBorder {
    border-color: #aa5757;
  } */

  .cardRellenoRecomendados:hover {
    transform: scale(1.05);
  }

  .imagenRellenoRecomendados {
 
    width: 85%;
    margin: 1 auto;
    
  }

  .parrafoCardRecomendados{
    width: 90%;
    color: #9A91D2; 
    font-size: 75%;
    margin: 0 auto;
    @media (min-width:1600px) {
      font-size: 130%;
    }


  }