.CuestionariosAdmin {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.columns-container * {
  box-sizing: border-box;
}
/* .columns-container, .left-container > .column,
.left-container > .column-bottom, .right-container {
  border: 1px solid black;
} */
.columns-container {
  max-width: 1700px;
  width: 85%;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  gap: 50px 20px;
}
.left-container {
  flex-basis: calc(100% * (2/3) - 25px);
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  gap: 25px;
}
.left-container > .column {
  flex: 1 1 calc(100% * (1/2) - 20px);
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
}
.left-container > .column-bottom {
  flex: 1 1 calc(100%);
}
.right-container {
  flex-basis: calc(100% * (1/3) - 25px);
  display: flex;
  flex-direction: column;
  align-items: center;
}
.separator {
  height: auto;
  border: 2px solid #518ea63d;
  border-radius: 99rem;
}


@media (max-width: 767px) {
  .separator {
    display: none;
  }
  .columns-container {
    justify-content: center;
  }
}


.big-buttonCuestionariosAdmin {
  padding: 10px 20px;
  font-size: 20px;
  width: 100%;
  /* max-width: 450px; */
  min-width: 200px;
  margin: 10px 0; /* Margen ajustado para botones dentro de la columna */
  border: none; /* Quitar el borde */
  border-radius: 5px;
  cursor: pointer; /* Cambiar el cursor al pasar sobre el botón */
}
.big-buttonCuestionariosAdmin.descargar-button {
  max-width: 400px;
  background-color: #518ea6;
  color: white;
}
.big-buttonCuestionariosAdmin.descargar-button:hover {
  background-color: #518ea6;
}

.big-buttonCuestionariosAdmin:hover {
  background-color: #a7a7a7; /* Cambiar el color de fondo al pasar el cursor */
  color: white; /* Cambiar el color del texto al pasar el cursor */
}

.big-textoCuestionariosAdmin {
  padding: 10px 20px;
  font-size: 20px;
  margin: 10px 0; /* Margen ajustado para botones dentro de la columna */
  border: none; /* Quitar el borde */
  cursor: pointer; /* Cambiar el cursor al pasar sobre el botón */
}

.download-container {
  margin-top: 20px; /* Separación del botón de descarga de las columnas */
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding: 20px;
  align-items: center; /* Centra los elementos horizontalmente */
  justify-content: center; /* Centra los elementos verticalmente */
}

.big-buttonCuestionariosAdmin.active {
  background-color: #518ea6; /* Cambia este color al que desees */
  color: white;
}

.advertencia-texto {
  color: red;
  font-weight: bold;
  margin-bottom: 10px;
}
