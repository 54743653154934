
.LoginAdmin {
    margin-top: 4rem;
}

.LoginAdmin form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin: 3rem;
}

.LoginAdmin form input {
  width: 20rem;
  padding: .3rem .5rem;
  border: 2px solid #518ea6;
  border-radius: .75rem;
}
.LoginAdmin form input[type=submit] {
  width: 10rem;
  background-color: transparent;
  color: #518ea6;
  border: 2px solid #518ea6;
  border-radius: .75rem;
  cursor: pointer;
}
.LoginAdmin form input[type=submit]:hover {
  background-color: #518ea6;
  color: white;
}
.LoginAdmin form input:focus {
  outline: 2px solid #518ea6;
}